<template>
  <div>
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Category</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart name-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- name -->
                    <div>
                      <div class="form-group col">
                        <label for="name">Name:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="name"
                          placeholder="Enter name"
                          v-model.trim="details.name"
                          @input="setTouch('name')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.name.required && $v.details.name.$error"
                        >
                          Name is required
                        </div>
                      </div>
                    </div>

                    <!-- <div>
                      <div class="form-group col">
                        <label for="image">image:</label>
                        <v-file-input
                          prepend-icon=""
                          type="file"
                          class="form-control"
                          id="curated-image"
                          @change="handleImage"
                          v-model.trim="details.image"
                          placeholder=" Select Image"
                          accept="image/jpeg, image/png"
                        ></v-file-input>
                      </div>
                      <div
                        class="preview-image border p-2 mt-3 ml-3"
                        v-if="url"
                      >
                        <v-img :src="url" height="100%" contain></v-img>
                      </div>
                      <div
                        class="validation"
                        v-if="
                          !$v.details.image.required && $v.details.image.$error
                        "
                      >
                        image is required
                      </div>
                    </div> -->

                    <div class="d-flex">
                      <div class="form-group col">
                        <div>
                          <label for="image">Mobile Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            ( W 163 &#10005; 163 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.imageMobile"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileImage"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.imageMobile.required && $v.details.imageMobile.$error"
                          >
                            Mobile Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                          <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col">
                        <div>
                          <label for="image"> Desktop Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (288 W &#10005; 288 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.image"
                            placeholder=" Select Image"
                            @change="handleDesktopImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.image.required && $v.details.image.$error"
                          >
                            Desktop Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                          <v-img :src="desktopUrl" height="230" contain></v-img>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="form-group col">
                        <label for="link">Sequence:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="sequence"
                          placeholder="Enter Sequence"
                          v-model.trim="details.sequence"
                          @input="setTouch('sequence')"
                          min="0"
                          max="10"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.sequence.required && $v.details.sequence.$error"
                        >
                          sequence is required
                        </div>
                      </div>
                    </div>

                    <div class="ml-4 mt-2">
                      <label for="gender">Gender</label>
                      <div class="d-flex">
                        <div>
                          <input
                            type="radio"
                            v-model="details.gender"
                            value="Male"
                            class="mr-1"
                            @change="updateCategory()"
                          />
                          <label>Male</label>
                        </div>
                        <div class="ml-4">
                          <input
                            type="radio"
                            v-model="details.gender"
                            value="Female"
                            class="mr-1"
                            @change="updateCategory()"
                          />
                          <label>Female</label>
                        </div>
                      </div>
                      <div
                        class="validation"
                        v-if="!$v.details.gender.required && $v.details.gender.$error"
                      >
                        Gender is required
                      </div>
                    </div>
                    <!-- <div class="form-group mx-4 pt-4">
                      <label for="gender">Assign Categories</label>
                      <v-select
                        :items="categories"
                        v-model="details.assignedCategories"
                        append-icon="▼"
                        multiple
                        class="mx-8"
                      >
                      </v-select>
                      <div
                        class="validation"
                        v-if="!$v.details.gender.required && $v.details.gender.$error"
                      >
                        Category Selection is required
                      </div>
                    </div> -->
                    <div class="form-group mx-4 pt-4" v-if="categories">
                      <label for="email"> Assign Categories:</label>

                      <v-select
                        :items="categories"
                        v-model="details.assignedCategories"
                        append-icon="▼"
                        multiple
                        item-text="name"
                        class="form-control"
                        prepend-icon=""
                      >
                      </v-select>

                      <!-- <div
                        class="validation"
                        v-if="
                          !$v.details.assignedCategories.required &&
                          $v.details.assignedCategories.$error
                        "
                      >
                        Assigned Categories is required
                      </div> -->
                    </div>
                    <div>
                      <div class="form-group col">
                        <label for="link">Link:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="link"
                          placeholder="Enter Url"
                          v-model.trim="details.link"
                        />
                        <!-- <div
                          class="validation"
                          v-if="!$v.details.link.required && $v.details.link.$error"
                        >
                          Link is required
                        </div> -->
                      </div>
                    </div>
                    <!-- button -->
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import productService from '../../services/productService';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  data() {
    return {
      details: {
        name: '',
        sequence: 0,
        imageMobile: '',
        image: '',
        gender: '',
        assignedCategories: [],
        link: '',
      },
      submitStatus: null,
      mobileUrl: null,
      desktopUrl: null,
      categories: [],
      Women: [],
      Men: [],
    };
  },

  validations: {
    details: {
      name: {
        required,
      },
      sequence: {
        required,
      },
      imageMobile: {
        required,
      },
      image: {
        required,
      },
      gender: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'name') {
        this.$v.details.name.$touch();
      } else if (fields === 'mobileImage') {
        this.$v.details.imageMobile.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'sequence') {
        this.$v.details.sequence.$touch();
      } else if (fields === 'gender') {
        this.$v.details.gender.$touch();
      }
      //  else if (fields === 'assignedCategories') {
      //   this.$v.details.assignedCategories.$touch();
      // }
    },
    // this is use for save button
    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.submitStatus = 'OK';
        console.log(details, 'details');
        details.sequence = parseInt(details.sequence);
        const result = await homeService.createCategory(details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewCategories');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.image = result.data.meta.location;
          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = await URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    updateCategory() {
      console.log('here also');
      if (this.details.gender == 'Female') {
        this.categories = this.Women;
      } else if (this.details.gender == 'Male') {
        this.categories = this.Men;
      }
    },
    async getAllCategoryItems() {
      this.categories = [];
      const result = await productService.getAllCategoryItems();

      this.Women = await result.data.female.map((item) => {
        return item.name;
      });

      this.Men = await result.data.male.map((item) => {
        return item.name;
      });
    },
  },

  created() {
    this.getAllCategoryItems();
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 300px;
  height: 350px;
}
</style>
